.TemplateContent {
    width: 100%;
    height: 100vh;
    background: #F4F5FA;
    padding: 15px;
    padding-bottom: 0px;
}

.CancelBtn {
    border: none;
    background-color: #F0F0F0;
    color: #545454;
    padding: 6px 20px;
    border-radius: 5px;
    margin-left: 10px;
    font-size: 17px;
    font-weight: 600;
}

.DisabledBtn {
    border: none;
    background-color: #F0F0F0;
    color: #D8D8D8;
    padding: 6px 20px;
    border-radius: 4px;
    margin-left: 10px;
    font-size: 17px;
    font-weight: 600;
}

.TokenData {
    border: 1px solid #DDDDDD;
    padding: 3px 10px;
}

.TemplateData {
    border-bottom: 1px solid #DDDDDD;
    margin-bottom: 10px 
}

/*  ----- Delete template css ---------*/
.AlertHeading {
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
}

.AlertBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AlertBody img {
    width: 80px;
    height: 80px;
    margin-top: 10px;
}

.AlertBody p {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    color: #000000;
    width: 63%;
    text-align: center;
    margin: 0;
    padding-top: 15px;
}

.AlertFooter {
    margin: 0px 40px;
    display: flex;
    justify-content: center;
}

.AlertYes {
    width: 90px;
    height: 40px;
    border-radius: 4px;
    background: #48517A;
    color: #ffffff;
    border: #48517A;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
}

.AlertCancel {
    width: 90px;
    height: 40px;
    border-radius: 4px;
    background: #e74f5b;
    color: #ffffff;
    border: #e74f5b;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
}

.characterCount {
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;
    /* width: 60px; */
    font-size: 12px;
}