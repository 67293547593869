.chatContainer {
    width: 100%;
    height: 100%;
}

.chatContainer h2 {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
    line-height: 22.59px;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 14px 10px;
    background-color: #ffff;
    margin: 0;
    color: #202020;
    height: 8%;
}

.chatContainer h2 span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
}

.message {
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.userMessage p {
    background-color: #f5f5f5;
    text-align: left;
    max-width: 55%;
    width: fit-content;
    padding: 10px 15px;
    border-radius: 0px 15px 15px 15px;
    margin: 7px 0px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.57px;
    font-family: Mulish;
    color: #17191a;
}

.botMessage {
    color: #FFFFFF;
    text-align: right;
    align-self: flex-end;
    max-width: 55%;
    margin-bottom: 10px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: flex-end;
}

.botMessage p {
    background-color: #199FFF;
    border-radius: 15px 0px 15px 15px;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.57px;
    font-family: Mulish;
    color: #FFFFFF;
    text-align: left;
    word-break: break-word;
}

.message h6 {
    font-family: Mulish;
    margin: 0;
    font-size: 11px;
    font-weight: 400;
    line-height: 12.55px;
    /* color: #757C80; */
    color: #999999;
    width: fit-content;
    display: unset;
}

.failedText {
    margin: 0;
    display: flex;
    justify-content: end;
    /* color: #DBA48F; */
    color: #e45839;
    align-self: flex-end;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.55px;
    margin-bottom: 12px;
}

.message p {
    margin: 0;
    margin: 3px 0px;
}


/* ------ user detail css --------------- */
.ChatProfile {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 92%;
}

.chat {
    border: 1px solid #DBDBDB;
    border-left: none;
    padding: 20px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    /* max-height: calc(100vh - 156px); */
    /* max-height: calc(100vh - 106px); */
    height: 100%;
    overflow: auto;
    scrollbar-width: thin;
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 35px;
}

/* ----------- message input css ----------- */
.ChatMessage {
    position: relative;
    width: 100% !important;
    height: 100%;
}

.Section {
    background-color: #ffff;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding-bottom: 6px;
    border-top: 1px solid #ccc;
}

.empty {
    border: none;
    padding: 5px 15px;
    margin: 0px 10px;
    display: flex;
    align-items: center;
    color: #ffff;
    background-color: #E6E6E6;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    font-family: Mulish;
}

.empty svg {
    color: #FFFFFF !important;
}

.empty svg:hover {
    color: #FFFFFF !important;
    fill: #FFFFFF !important;
}

.typing {
    border: none;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    background-image: linear-gradient(to right, #EB3349, #F45C43);
    border-radius: 5px;
    color: #ffff;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    font-family: Mulish;
    margin: 0px 10px;
}

.typing svg {
    color: #FFFFFF !important;
}

.typing svg:hover {
    color: #FFFFFF !important;
    fill: #FFFFFF !important;
}

.ButtonGroup {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 15px;
    cursor: pointer;
    padding-bottom: 5px;
    padding-left: 10px;
}

.iconss {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.ButtonGroup svg {
    color: #85858585;
}

.ButtonGroup svg:hover {
    color: #626262;
    fill: #626262;
}

.SendButton {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 5px;
}

.characterCount {
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;
    width: 60px;
    font-size: 12px;
}

.ImageContainer {
    position: absolute;
    bottom: 85px;
    background-color: whitesmoke;
    max-width: 250px;
    height: 100px;
    padding: 7px;
    left: 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.ImageContainer svg {
    position: absolute;
    right: 7px;
    top: 10px;
    color: #FFFFFF;
    background-color: #626262;
    border-radius: 50%;
}

.NoContact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.messageImageContainer {
    max-width: 200px;
    width: 100%;
    height: auto;
    max-height: 200px;
    min-height: 150px;
    background-color: #efefef;
    padding: 4px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -.15px;
    line-height: 20px;
    color: #757C80;
    object-fit: cover;
}

.FailedImageContainer {
    width: 70px;
    height: 70px;
    background-color: #efefef;
    padding: 20px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -.15px;
    line-height: 20px;
    color: #e45839;
}
.imageBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.uploadBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;
    width: 190px;
    text-align: center;
}

.uploadLabel {
    cursor: pointer;
    align-items: center;
    margin-top: 10px;
    color: #333333;
    font-size: 11px;
    font-weight: 600;
}

.uploadIcon {
    border: 2px solid #dedede;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 50px;
    background-color: #f9f9f9;
}

.uploadBox p {
    font-size: 12px;
    font-weight: 600;
}

.uploadIcon svg {
    color: #9e9e9e;
    font-size: 40px;
    font-weight: 600;
    display: flex;
    margin: 10px auto;
}

.uploadImg {
    width: 100%;
    max-width: 267px;
    margin: 20px auto;
    border-radius: 5px;
    height: 100%;
    max-height: 276px;
    object-fit: contain;
}

.imgUpload {
    position: relative;
}

.imgUpload .afterUploadImg {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border: 1px solid #dedede;
    padding: 1px 1px;
    border-radius: 50px;
}

.closeIcon {
    font-size: 18px;
    position: absolute;
    left: 60px;
    top: 0px;
    color: #fff;
    background-color: #406394;
    font-weight: 600;
    border-radius: 50%;
}
.spanBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.spanBox h1 {
    font-family: Mulish;
    font-size: 15px;
    font-weight: 600;
    line-height: 15.06px;
    padding-inline: 0px;
    color: #858585cf;
    margin-bottom: 4px;
}
.spanBox span{
    font-size: 13px;
    font-weight: 500;
    line-height: 16.32px;
    color: #858585cf;
}