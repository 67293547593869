.TemplateContent {
  width: 100%;
  height: 100vh;
  background: #f4f5fa;
  padding: 15px;
  padding-bottom: 0px;
}
.TemplateContent h1 {
  font-family: Mulish;
  font-size: 25px;
  font-weight: 700;
  line-height: 31.38px;
  margin: 10px 0px 5px 20px;
}

.CancelBtn {
  border: none;
  background-image: linear-gradient(to right, #EB3349, #F45C43);
  color: #FFFFFF;
  padding: 6px 20px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 17px;
  font-weight: 600;
  height: 40px;
}

.TemplateData {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px;
}

.contactInfo p{
  color: #333333;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
  margin: 0px;
  padding-top: 10px;
}

.contactInfo span{
  color: #757C80;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
}
