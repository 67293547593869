.Menubar {
    background-image: url("../../Assest/Images/SidebarBg.png");
    background-size: cover;
    height: 100vh;
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
    gap: 35px;
    box-shadow: 0px 0px 15px 0px rgb(233, 229, 229);
}

.PageLink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Logo {
    height: 35px !important;
    width: 35px !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.HoverIcon {
    background-image: url("../../Assest/Images/HoverMessage.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
}

.ActiveHoverIcon {
    background-image: url("../../Assest/Images/Activemessage.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
}


.ActiveIcon {
    background-image: url("../../Assest/Images/Activemessage.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.25);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
}

.InactiveIcon {
    background-image: url("../../Assest/Images/Inactivemessage.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
    filter: invert(7%) brightness(130%);
}
/* ----------- Template icon css------------ */
.Hovertemplate {
    background-image: url("../../Assest/Images/Hovertemplate.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
}

.ActiveHovertemplate {
    background-image: url("../../Assest/Images/Activetemplate.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
}

.Activetemplate {
    background-image: url("../../Assest/Images/Activetemplate.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.25);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
}

.Inactivetemplate {
    background-image: url("../../Assest/Images/Inactivetemplate.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
    filter: invert(7%) brightness(130%);
}

/* ---------- Contact Icon Css ----------------- */
.HoverConatct {
    background-image: url("../../Assest/Images/HoverContact.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
}

.ActiveHoverConatct {
    background-image: url("../../Assest/Images/ActiveContact.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
}

.ActiveContact {
    background-image: url("../../Assest/Images/ActiveContact.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.25);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
}

.InactiveContact {
    background-image: url("../../Assest/Images/InactiveContact.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
    filter: invert(7%) brightness(130%);
}

/* ---------- TimeZone Icon Css ----------------- */
.HoverTimeZone {
    background-image: url("../../Assest/Images/Hover_TimeZone_ico.svg");
    background-repeat: no-repeat;
    /* background-size: cover; */
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    /* background-size: auto; */
    background-size: 22px 22px;
    text-align: center;
    position: relative;
}

.ActiveHoverTimeZone {
    background-image: url("../../Assest/Images/Active_TimeZone_ico.svg");
    background-repeat: no-repeat;
    /* background-size: cover; */
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    /* background-size: auto; */
    background-size: 22px 22px;;
    text-align: center;
    position: relative;
}

.ActiveTimeZone {
    background-image: url("../../Assest/Images/Active_TimeZone_ico.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.25);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    /* background-size: auto; */
    background-size: 22px 22px;;
    text-align: center;
    position: relative;
}

.InactiveTimeZone {
    background-image: url("../../Assest/Images/InactiveTimeZone_ico.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    /* background-size: auto; */
    background-size: 22px 22px;;
    text-align: center;
    position: relative;
    filter: invert(7%) brightness(130%);
}

/*  Setting Icon CSS */
.HoverSetting {
    background-image: url("../../Assest/Images/HoverSetting.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
}

.ActiveHoverSetting {
    background-image: url("../../Assest/Images/ActiveSetting.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
}

.ActiveSetting {
    background-image: url("../../Assest/Images/ActiveSetting.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.25);
    border: none;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
}

.InactiveSetting {
    background-image: url("../../Assest/Images/InactiveSetting.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    margin: 12px;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    background-position: center;
    background-size: auto;
    text-align: center;
    position: relative;
    filter: invert(7%) brightness(130%);
}

@media (min-width: 561px) and (max-width: 800px) {
    .Menubar {
        width: 60px;
    }
}

@media only screen and (max-width: 560px) {
    .Menubar {
        width: 60px;
    }

}