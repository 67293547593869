.timezoneContent {
    width: 100%;
    height: 100vh;
    background: #F4F5FA;
    padding: 15px;
    padding-bottom: 0px;
}
.mainDiv{
    margin: auto;
    padding: 10px;
    /* background-color: #8c8d94; */
}

.listGroup{
    max-height: 300px;
    margin-bottom: 10px;
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    cursor: pointer;
}

  
  .listGroup:hover {
    background-color: #f8f9fa; /* Light gray background on hover */
    transition: background-color 0.3s;
  }

  .centerDiv {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}