.chatList {
  border: 1px solid #dbdbdb;
  height: 100%;
  background-color: #f3f2f2;
  /* margin-left: 15px; */
  /* background-color: #fafafa; */
  /* width: 270px; */
  width: 330px;
}

.SearchBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 15px;
  padding-bottom: 12px;
  background-color: #f9f9f9;
}

.ChatBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  max-height: calc(100vh - 148px);
}
.ChatBox:focus-visible {
  outline: none;
}

.contactChatBox{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  max-height: calc(100vh - 148px);
}
.contactChatBox:focus-visible {
  outline: none;
}

.userInfo {
  cursor: pointer;
  padding: 15px;
  background-color: #ffffff;
  margin: 1px 0px;
}

.userInfo:hover {
  background-color: #f5f5f58f;
}

.userInfo.active {
  background-color: #f5f5f58f;
}

.userInfo h3 {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  margin: 6px 0px;
  line-height: 20px;
  color: #17191a;
}

.userInfoBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userInfoBox h5 {
  font-family: Mulish;
  font-size: 11px;
  font-weight: 500;
  color: #757c80;
  line-height: 12.55px;
  margin: 0;
}

.userInfo p {
  font-family: Mulish;
  font-size: 13px;
  font-weight: 500;
  line-height: 16.32px;
  /* color: #5c5c5c; */
  color: #757c80;
  margin: 0;
  word-break: break-all;
  width: 80%;
}
.chatContainer {
  width: 100%;
  height: 96vh;
}

.chatContainer h2 {
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.59px;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 14px 10px;
  background-color: #ffff;
  margin: 0;
  color: #202020;
}

.chatContainer h2 span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
}

.NoContact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.noChatSelected {
  width: 40%;
  max-width: 400px;
  min-width: 400px;
}

.SearchBoxNew {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 15px;
  padding-bottom: 12px;
}

.crossIcon{
  border-radius: 20px;
  background-size: 24px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  padding: 7px;
}

.crossIcon:hover {
  background-color: #e4e4e47c;
}

@media only screen and (min-width: 1500px) {
  .chatList {
    justify-content: space-between;
  }
}

@media (min-width: 561px) and (max-width: 900px) {
  .chatContainer {
    width: 100%;
  }

  .chatList {
    width: 38%;
  }

  .userInfo h3 {
    font-size: 16px;
  }

  .userInfoBox h5 {
    font-size: 12px;
  }

  .userInfo p {
    font-size: 14px;
  }

  .userProfile img {
    margin: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .chatContainer {
    padding: 0;
  }

  .userProfile {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    right: 0;
    width: 200px;
    padding: 10px;
    gap: 30px;
  }

  .chatList {
    width: 100%;
    display: unset;
    /* margin: 0px 10px; */
    flex: 1;
  }

  .userInfo h3 {
    font-size: 16px;
  }

  .userInfoBox h5 {
    font-size: 12px;
  }

  .userInfo p {
    font-size: 14px;
  }

  .userMessage p {
    max-width: 75%;
  }

  .botMessage {
    max-width: 75%;
  }
}
