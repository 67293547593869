.MainScreen {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.chatContent {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #f4f5fa;
}

/* / ----Top heading css-------- / */

.TopHeading {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.TopHeading button {
  border: none;
  background-color: #48517a;
  color: #ffffff;
  padding: 0px 20px;
  border-radius: 4px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
}

.HeadingName {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.HeadingName h1 {
  font-family: Mulish;
  font-size: 20px;
  font-weight: 800;
  line-height: 25px;
  margin: 0;
}

.SearchInput {
  height: 40px;
  border: 1px solid #c7c7c7;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 0px rgb(233, 229, 229);
  display: flex;
  align-items: center;
  width: 285px;
  background-color: #ffffff;
}

.SearchInput .form-control {
  border: none;
  width: 100%;
  outline: none;
  border: none;
  padding-left: 0;
}

.SearchInput .form-control:focus {
  box-shadow: none;
  outline: none;
  border: none;
}

.SearchInput .form-control::placeholder {
  font-family: Mulish;
  font-size: 11px;
  font-weight: 300;
  line-height: 13.81px;
  color: #626262;
}

.SearchInput .SearchIconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 50px;
  position: absolute;
  right: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: linear-gradient(89.98deg, #0052ab 0.01%, #038e9f 99.98%),
    linear-gradient(0deg, #027ca2, #027ca2);
}

/* / -------------- / */
.tooltip-inner {
  background-color: #ffff;
  padding: 10px;
  font-size: 20px;
  box-shadow: 0px 0px 15px 0px rgb(182, 180, 180);
  border-radius: 10px;
  color: red;
}

div#popover-basic {
  background: black;
  color: white;
  border-radius: 10px;
  width: 430px;
  line-height: 22px;
  font-size: 15px;
}

.popover-body {
  color: white;
  background-color: #000000;
  border-radius: 4px;
  border: none;
  font-size: 10px;
  font-weight: 500;
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after,
.bs-popover-start > .popover-arrow::after {
  border-left-color: black;
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after,
.bs-popover-top > .popover-arrow::after {
  border-top-color: black !important;
}

.TemplatePage {
  display: flex;
  width: 100%;
}

.SearchTemplate {
  height: 40px;
  border: 1px solid #c7c7c7;
  border-radius: 54px;
  display: flex;
  align-items: center;
  background-color: #ffff;
  width: 383px;
}

.SearchTemplate .form-control {
  border: none;
  width: 80%;
  padding: 2px 0px;
}

.SearchTemplate .form-control::placeholder {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #828282;
}

.SearchTemplate .form-control:focus {
  border: none;
  outline: none;
}

.SearchTemplate .SearchIconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 50px;
  position: absolute;
  right: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: linear-gradient(89.98deg, #0052ab 0.01%, #038e9f 99.98%),
    linear-gradient(0deg, #027ca2, #027ca2);
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center;
    align-items: center; */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  background: #ffff;
  overflow: auto;
  scrollbar-width: thin;
  height: fit-content;
  min-height: 154px;
  max-height: calc(100vh - 78px);
}

.card {
  width: calc(33.33% - 20px);
  margin: 10px;
  padding: 8px 16px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  height: 154px;
}

.card h2 {
  margin-top: 0;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 18px;
  color: #000000;
}

.card span {
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  color: #757c80;
}

.card p {
  margin: 6px 0;
  font-size: 11px;
  font-weight: 400;
  color: #626262;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 4; */
  -webkit-box-orient: vertical;
}

.dropdown {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.dropbtn {
  border: none;
  background: none;
  font-size: 25px;
  font-weight: 800;
  position: relative;
  color: #9c9b9b;
}

button#dropdown-button-drop {
  background-color: transparent;
  border: none;
  color: #9c9b9b;
  font-size: 22px;
  font-weight: 800;
}

button#dropdown-button-drop:hover {
  color: #626262 !important;
}

button#dropdown-button-drop::after {
  display: none;
}

button#dropdown-button-drop:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.dropdown-menu.show {
  position: absolute;
  right: 0;
  display: flex;
  height: 60px;
  flex-direction: column;
  top: 30px;
  background: #ffff;
  box-shadow: 0px 0px 5px 0px rgb(196, 195, 195);
  width: 80px;
  padding: 5px;
  border-radius: 5px;
  align-items: flex-start;
}

a.dropdown-item {
  color: black;
  padding: 5px;
  font-size: 10px;
  font-weight: 500;
  border: none;
  background: none;
}

.inboxCard {
  width: calc(33.33% - 20px);
  margin: 10px;
  padding: 8px 12px;
  /* border: 1px solid #dddddd; */
  min-height: 77px;
  background-color: #ffffff;
}
.inboxCard h2 {
  margin-top: 0;
  font-size: 18px;
  font-weight: 700;
  font-family: Mulish;
  line-height: 22.59px;
  color: #17191a;
}

.inboxCard span {
  font-size: 13px;
  font-weight: 700;
  line-height: 16.32px;
  color: #757c80;
}

.inboxCard .dropdown-menu.show {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  background: #ffff;
  box-shadow: 0px 0px 8px 2px rgba(165, 165, 165, 0.2);
  padding: 5px;
  border-radius: 5px;
  align-items: flex-start;
  height: auto;
  width: auto;
  border: none;
}
.inboxCard a.dropdown-item {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.57px;
  border: none;
  background: none;
  padding: 10px;
}

.inboxCard a.dropdown-item img {
  padding-right: 5px;
  width: 24px;
  height: 19px;
}

.inboxCard a.dropdown-item span {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.57px;
  border: none;
  background: none;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #ffff;
}

.CreateInput {
  height: 60px;
}

.modal-dialog {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border: none;
}

.modal-header {
  background-color: #eb3349;
  color: #ffffff;
}

.modal-body {
  margin: 20px 0px;
}

.modal-title.h4 {
  font-family: Mulish;
  font-size: 17px;
  font-weight: 800;
  line-height: 21px;
}

.modal-footer {
  border-top: none;
}

.form-group {
  margin-bottom: 8px;
  display: flex;
}

input#exampleInputEmail1 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  border: none;
  padding: 2px;
  color: #000000;
  padding-left: 6px;
}

input#exampleInputEmail1:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #d6d6d6;
  padding-left: 6px;
}

.Inputs input.form-control {
  width: 100%;
  padding: 12px;
  height: 41px;
  box-sizing: border-box;
  margin-bottom: 16px;
  border-bottom: none;
}

.Inputs input.form-control:focus {
  border: none;
}

input.form-control {
  border: none;
  border-bottom: 0.5px solid #d6d6d6;
}

input.form-control:focus {
  border-bottom: 0.5px solid #d6d6d6;
  box-shadow: none;
}

textarea.form-control {
  border: none;
  border-bottom: 0.5px solid #d6d6d6;
  scrollbar-width: thin;
}

.tokenBtn {
  border: none;
  background-color: transparent;
  color: red;
  /* padding-top: 15px; */
}

textarea.form-control:focus {
  border-bottom: 0.5px solid #d6d6d6;
  box-shadow: none;
}

.error {
  color: red !important;
  font-size: 12px;
}

.NoDataFound {
  width: 100%;
  height: 85%;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NoResultFound {
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* / ------------ Emoji css --------------- / */

em-emoji-picker {
  position: absolute;
  bottom: 30px !important;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 10;
  height: 400px;
  /* max-height: 18em !important; */
}

.custom-input-textarea {
  border: none;
  width: 100%;
  color: black;
  padding: 9px 9px 0 !important;
  font-size: 16px;
  font-family: sans-serif;
}

.custom-input-textarea:focus-visible {
  border: none;
  outline: none;
}
.custom-input-textarea:focus {
  border: none;
  outline: none;
  outline-offset: 0;
}

.Btn-drop.dropdown {
  display: flex;
  justify-content: end;
}

.table-bordered td,
.table-bordered th {
  border: none;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 70px;
  color: #17191a;
}

.table thead th {
  border-bottom: none;
  color: #757c80;
  font-size: 14px;
  font-weight: 600;
}

.NotifiyMsg {
  font-size: 12px;
  font-weight: 600;
  color: #31a7e7 !important;
  margin-top: 5px;
  cursor: pointer;
  text-align: center;
}

.TokenModal .modal-body {
  margin: 0px;
  padding: 0;
  height: 300px;
  overflow: auto;
}

.ContactModal input.form-control {
  background-color: #f9f9f9;
  height: 40px;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: solid 1px #e6e6e6;
}

.ContactModal .PhoneInput {
  padding: 0px 0px 15px 0px;
  width: 100%;
}

input.form-control.Contact_PhoneInput__isoBi {
  padding: 0px 45px;
}

.react-tel-input {
  /* margin-bottom: 20px; */
}

.TemplateModal .modal-body {
  height: 400px;
  overflow: auto;
  scrollbar-width: thin;
  margin: 0;
  border-top: 1px solid #e1dfdf;
}

/* .TemplateModal .modal-header {
    background-color: #f7f7f7;
    color: #000000;
} */

.FirbaseToaster {
  display: flex;
  justify-content: end;
  align-items: center;
  /* width: 100%; */
  z-index: 9999999999;
  position: absolute;
  right: 0;
  padding-right: 10px;
  padding-top: 5px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.ImageModal_overlay__yca-V {
  background-color: #000000;
}

.DropdownOptions {
  box-shadow: 0 1px 0 0 #e6e6e6;
  display: flex;
  flex-direction: row;
  min-height: 56px;
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
  height: 70px;
  background-color: #fcfcfc;
}
.DropdownOptions button#dropdown-item-button {
  background: none !important;
  color: #000000;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  border: none;
  box-shadow: none;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.DropdownOptions .dropdown-menu.show {
  position: absolute;
  right: 0;
  display: flex;
  height: auto !important;
  /* width: auto; */
  min-width: 240px;
  flex-direction: column;
  padding: 0;
  top: 30px;
  background: #ffff;
  border: none;
  border-radius: 0%;
  align-items: flex-start;
  box-shadow: 0px 0px 8px 2px rgba(165, 165, 165, 0.2);
}
.DropdownOptions a.dropdown-item {
  color: black;
  padding: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
  border-bottom: 0.7px solid #dbdbdbee;
  background: none;
}

.DropdownOptions a.dropdown-item:hover {
  background-color: #f5f5f58f;
}

.DropdownOptions a.dropdown-item span {
  font-size: 11px;
  font-weight: 500;
  line-height: 13.81px;
  color: #757c80;
}

.DropdownOptions p {
  /* border-radius: 20px;
  background-size: 24px;
  width: 38px;
  height: 38px;
  display: block;
  padding: 4px;
  cursor: pointer;
  background-color: rgb(208, 2, 27); */
  background-image: url("../../Assest/Images/Addchat1.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  background-color: rgb(208, 2, 27);
  border: none;
  width: 36px;
  min-height: 36px;
  max-height: 36px;
  padding: 10px;
  display: block;
  box-sizing: border-box;
  background-position: center;
  background-size: auto;
  text-align: center;
  position: relative;
  margin: 0;
}
.DropdownOptions h1 {
  color: #000000;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  margin: 0;
}
.textDisplay {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px;
  position: relative;
}

.textDisplay h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22.59px;
  color: #000000;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.textDisplay h2:hover::after {
  content: attr(data-fulltext); /* Grabs the full text */
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  top: 100%; /* Positions below the text */
  left: 0;
  z-index: 10;
  font-size: 13px;
  line-height: 1.2;
  max-width: none;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  visibility: visible;
}

.textDisplay h2::after {
  content: "";
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
}

.textDisplay span {
  font-size: 13px;
  font-weight: 500;
  line-height: 16.32px;
  color: #757c80;
  margin-top: 5px;
}

.IndexHeader {
  background-color: transparent;
  color: #000000;
}

.IndexHeader .modal-title.h4 {
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.59px;
}
.indexModal input.form-control {
  background-color: #ffffff;
  height: 50px;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: 0.7px solid #dbdbdb;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.59px;
}

.btn-close:focus {
  outline: none;
  box-shadow: none;
}

@media (min-width: 630px) and (max-width: 840px) {
  .TopHeading button {
    padding: 8px 15px;
    font-size: 13px;
  }
}

@media (min-width: 801px) and (max-width: 1023px) {
  .SearchTemplate {
    width: 280px;
  }
}

@media (min-width: 561px) and (max-width: 800px) {
  .card {
    width: calc(49.33% - 20px);
    padding: 10px;
  }

  .SearchTemplate {
    width: 260px;
  }

  .SearchTemplate .form-control {
    width: 50%;
  }
}

@media only screen and (max-width: 560px) {
  .card {
    width: calc(99.33% - 20px);
    padding: 10px;
  }

  .SearchTemplate {
    width: 66%;
  }

  .SearchTemplate .form-control {
    border: none;
    width: 65%;
    padding: 2px 0px;
  }

  .table-bordered td,
  .table-bordered th {
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 600px) {
  .SearchInput {
    width: 100%;
  }

  .modal-content {
    width: 75% !important;
  }
}

@media only screen and (max-width: 631px) {
  .grid-container {
    min-height: 154px;
    max-height: calc(100vh - 102px);
  }

  .NoDataFound {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
  }

  .table-bordered td,
  .table-bordered th {
    border: none;
    vertical-align: middle;
    font-size: 13px;
    font-weight: 500;
  }

  .table thead th {
    border-bottom: none;
    color: #757c80;
    font-size: 12px;
    font-weight: 600;
  }

  .TopHeading {
    margin: 10px 0px;
  }

  .TopHeading button {
    padding: 8px 10px;
    font-size: 12px;
  }

  .HeadingName {
    display: unset;
  }
}

@media only screen and (max-width: 450px) {
  .TopHeading button {
    padding: 8px 10px;
    font-size: 10px;
  }
}

.contact-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}

.SearchTemplateChat {
  height: 40px;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #ffff;
  width: 93%;
  margin: 15px;
}

.SearchTemplateChat .form-control {
  border: none;
  width: 80%;
  padding: 2px 0px;
}

.SearchTemplateChat .form-control::placeholder {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #828282;
}

.SearchTemplateChat .form-control:focus {
  border: none;
  outline: none;
}

.SearchTemplateChat .SearchIconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 50px;
  position: absolute;
  right: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: linear-gradient(89.98deg, #0052ab 0.01%, #038e9f 99.98%),
    linear-gradient(0deg, #027ca2, #027ca2);
}

.badge-light {
  display: inline-block;
  background-color: #eb3349;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  padding: 0px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  margin-left: 10px;
}

.badge-light-app-div {
  position: relative;
}

.badge-light-app {
  display: inline-block;
  background-color: #4a3033;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  padding: 0px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  position: absolute;
  right: 10px;
  z-index: 1;
  top: 7px;
}

.reactTelInput .react-tel-input {
  height: 40px;
}

.reactTelInput .react-tel-input .flag-dropdown {
  border-radius: 12px 0px 0px 12px !important;
}

.react-tel-input .selected-flag:focus,
.react-tel-input .selected-flag:hover {
  background-color: transparent !important;
}

.SearchInputTel {
  height: 40px;
  border: 1px solid #c7c7c7;
  box-shadow: 0px 0px 2px 0px rgb(233, 229, 229);
  display: flex;
  align-items: center;
  width: 260px;
  background-color: #ffffff;
  border-left: unset;
  border-radius: 0px 12px 12px 0px;
}
.SearchInputTel .form-control {
  border: none;
  width: 100%;
  outline: none;
  border: none;
  padding-left: 8px;
}

.SearchInputTel .form-control:focus {
  box-shadow: none;
  outline: none;
  border: none;
}
.SearchInputTel .form-control::placeholder {
  font-family: Mulish;
  font-size: 11px;
  font-weight: 300;
  color: #626262;
}

.dropdown-toggle::after {
  width: 11px !important;
  height: 10px !important;
}

.sendIndexButton button {
  background: none !important;
  /* color: #626262; */
  font-family: Mulish;
  font-size: 15px;
  font-weight: 600;
  line-height: 15.06px;
  border: none;
  box-shadow: none;
  padding-inline: 0px;
  color: #858585cf;
  display: flex;
}
.sendIndexButton button::after {
  margin-left: 0.555em !important;
  vertical-align: 0.1em !important;
  border-top: 0;
  border-right: 0.5em solid transparent !important;
  border-bottom: 0.5em solid !important;
  border-left: 0.5em solid transparent !important;
}

.sendIndexButton button:focus {
  box-shadow: none !important;
}

.sendIndexButton button:hover {
  color: #626262;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #626262 !important;
}
.sendIndexButton .dropdown-menu.show {
  position: absolute;
  right: 0;
  display: flex;
  height: auto !important;
  min-width: 240px;
  width: auto;
  flex-direction: column;
  padding: 0;
  top: 30px;
  background: #ffff;
  border: none;
  border-radius: 0%;
  align-items: flex-start;
  box-shadow: 0px 0px 8px 2px rgba(165, 165, 165, 0.2);
}

.sendIndexButton .dropdown-item {
  padding: 8px;
  border-bottom: 0.7px solid #dbdbdbee;
}

.sendIndexButton .dropdown-item:last-child {
  border-bottom: none;
}

.sendIndexButton label {
  color: black;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
  /* border-bottom: 0.7px solid #dbdbdbee; */
  background: none;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding-left: 4px;
  width: 100%;
}

.DropdownOptions label :hover {
  background-color: #f5f5f58f;
  text-decoration: none !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}

select.form-control {
  background-color: #ffffff;
  height: 45px !important;
  width: 100%;
  /* padding: 12px; */
  border-radius: 4px;
  border: 0.7px solid #dbdbdb;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.59px;
  margin-top: 15px;
}

select.form-control:focus {
  box-shadow: none;
  border-color: #dbdbdb !important;
}
