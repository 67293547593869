body {
  margin: 0;
  font-family: 'Mulish',"Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Mulish',"Poppins", sans-serif;
}


h1, h2, h3, h4, h5, h6 {
  font-family: 'Mulish', sans-serif;
}

/* Example usage for different font weights */
.normal-text {
  font-weight: 400; /* Regular weight */
}

.bold-text {
  font-weight: 700; /* Bold weight */
}

