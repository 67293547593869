.loaderOverlay {

    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    height: 77vh;
  }
  .loaderContainer img{
    width: 100px;
    height: 100px;
  }
  