.loaderOverlay {
    width: 100%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 0.5); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    height: 10px;
  }
  .loaderContainer img{
    width: 70px;
    height: 70px;
  }
  